import {ReactElement, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    OnboardingApi,
    ReferralLinkTextBody,
    ReferralsApi,
    UsersApi,
    ErrorType,
} from "@devour/client";
import {IStore} from "../redux/defaultStore";
import AuthHeaderLogo from "../components/auth/AuthHeaderLogo";
import {
    addError,
    decrementLoading,
    incrementLoading,
    toggleLoginOpen,
} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import {AiFillCheckCircle} from "react-icons/ai";
import classNames from "classnames";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import LoginEmail from "@/components/login/LoginEmail";
import LoginRegistration from "@/components/login/LoginRegistration";
import LoginVerificationCode from "@/components/login/LoginVerificationCode";
import {emitLoginEvent, emitRegisterEvent} from "@/hooks/useOnLogin";
import {
    inAppWallet,
    preAuthenticate,
} from "thirdweb/wallets/in-app";
import {Wallet} from "thirdweb/wallets";
import useOnAuthThirdweb from "@/hooks/useOnAuthThirdweb";
import {useConnect} from "thirdweb/react";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";

enum LoginState {
    EMAIL,
    REGISTRATION,
    VERIFY_CODE,
}

interface Props {
    isOnDialog?: boolean;
}

function ThirdwebLogin(props: Props): ReactElement {
    // keep order
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const signedUrlKey = searchParams.get("suk");
    const referralCodeQuery = searchParams.get("ref");
    const [loginState, setLoginState] = useState<LoginState>(LoginState.EMAIL);
    const [referralLinkText, setReferralLinkText] = useState<ReferralLinkTextBody | undefined>(undefined);
    const [email, setEmail] = useState<string | null>(null);
    const reduxReferralCode = useSelector((store: IStore) => store.affiliateStore.referralCode);
    const reduxReferralExpiry = useSelector((store: IStore) => store.affiliateStore.referralExpiry);
    const reduxReferralValid = reduxReferralCode && Date.now() < reduxReferralExpiry ? reduxReferralCode : "";
    const [referralCode, setReferralCode] = useState(reduxReferralValid ?? referralCodeQuery ?? "");
    const {isOnOverwolf} = useOverwolfInterop();
    const {connect} = useConnect();
    const {onLoginComplete} = useOnAuthThirdweb();

    useEffect(() => {
        /**
         * Get the user's email from the signed url key.
         *
         */
        async function getOnboardingData() {
            dispatch(incrementLoading());
            try {
                const formData = await new OnboardingApi(getConfig()).getOnboardingInformation({
                    signedUrlKey: signedUrlKey,
                });

                setEmail(formData.email || "");
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

        if (signedUrlKey) {
            void getOnboardingData();
        }
    }, [signedUrlKey]);

    useEffect(() => {
        /**
         * Get the current referral link text.
         *
         */
        async function getReferralLinkText() {
            try {
                const formData = await new ReferralsApi(getConfig()).getReferralLinkText({
                    referralCode: reduxReferralCode,
                });

                setReferralLinkText({
                    header: formData.header,
                    description: formData.description,
                    relationshipType: formData.relationshipType,
                });
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                dispatch(decrementLoading());
            }
        }

        // only render if there was a referral code stored
        if (reduxReferralCode) {
            void getReferralLinkText();
        }
    }, [reduxReferralCode]);

    async function onSubmitEmail(email: string): Promise<void> {
        try {
            const res = await new UsersApi(getConfig()).validateUserExistence({
                email,
            });
            setEmail(email);
            if (res._exists) {
                // send email verification code
                await preAuthenticate({
                    client: thirdwebAuthClient,
                    strategy: "email",
                    email, // ex: user@example.com
                });
                setLoginState(LoginState.VERIFY_CODE);
            } else {
                setLoginState(LoginState.REGISTRATION);
            }
        } catch (e) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: e.message || "Unable to process registration",
            }));
        }
    }

    async function onSubmitRegistration(referralCode?: string): Promise<void> {
        try {
            // send email verification code
            await preAuthenticate({
                client: thirdwebAuthClient,
                strategy: "email",
                email, // ex: user@example.com
            });
            setReferralCode(referralCode);
            setLoginState(LoginState.VERIFY_CODE);
        } catch (e) {
            dispatch(await addError({
                type: ErrorType.APP,
                message: e.message || "Unable to submit registration code",
            }));
        }
    }

    async function onSubmitVerificationCode(code: string): Promise<void> {
        await connect(async () => {
            try {
                const wallet = inAppWallet();
                await wallet.connect({
                    client: thirdwebAuthClient,
                    strategy: "email",
                    email,
                    verificationCode: code,
                });
                void onNewLoginComplete(wallet);
                return wallet;
            } catch (e) {
                dispatch(await addError({
                    type: ErrorType.APP,
                    message: e.message || "Unable to verify registration code",
                }));
            }
        });
    }

    async function onNewLoginComplete(wallet: Wallet<"inApp">) {
        const loginRes = await onLoginComplete(wallet.getAccount(), referralCode);

        dispatch(toggleLoginOpen(false));

        if (loginRes.isExistingUser) {
            emitLoginEvent();
        } else {
            emitRegisterEvent();
        }
    }

    function renderForm() {
        switch (loginState) {
            case LoginState.EMAIL:
                return (
                    <LoginEmail
                        initialEmail={email}
                        onSubmit={onSubmitEmail}
                    />
                );
            case LoginState.REGISTRATION:
                return (
                    <LoginRegistration
                        initialReferralCode={referralCode}
                        email={email}
                        onSubmit={onSubmitRegistration}
                    />
                );
            case LoginState.VERIFY_CODE:
                return (
                    <LoginVerificationCode
                        onSubmit={onSubmitVerificationCode}
                    />
                );
        }
    }

    return (
        <div
            className={classNames("login-page", {
                "is-on-dialog": !!props.isOnDialog,
            })}
        >
            {!props.isOnDialog &&
								<div className="login-page_spacer-top">
								    <AuthHeaderLogo/>
								</div>
            }

            <div className="login-page_content">
                {!isOnOverwolf &&
										<>
										    <h3 className="login-page_content_title">Craving something different?</h3>
										    <p className="login-page_content_subtitle">
														DevourGO delivers more than just food — serving up a whole new way to
														eat, play, and earn.
										    </p>
										</>
                }

                {reduxReferralValid && referralLinkText &&
										<div className="sign-up_content_referral-link-container">
										    <div className="sign-up_content_referral-link-container_header">
										        <AiFillCheckCircle
										            className="sign-up_content_referral-link-container_header_icon"/>
										        <p className="sign-up_content_referral-link-container_text">{referralLinkText.header}</p>
										    </div>
										    {referralLinkText.description && referralLinkText.description !== "" &&
														<p className="sign-up_content_referral-link-container_text">
														    {referralLinkText.description}
														</p>
										    }
										</div>
                }

                <h4 className="login-page_content_login-text">
                    {!isOnOverwolf ? "Login or Sign Up 🚀" : "Login or Sign Up"}
                </h4>
                {isOnOverwolf &&
										<p className="login-page_content_login-description">
												DevourGO delivers more than just food — serving up a new way to eat, play,
												and earn!
										</p>
                }

                {renderForm()}

                {!isOnOverwolf &&
										<div className="login-page_content_other-actions">
										    <p className="login-page_content_other-actions_owner">
														Are you a restaurant owner?{" "}
										        <a href={import.meta.env.VITE_MERCHANT_URL} rel="noopener noreferrer">
																Click here
										        </a>
										    </p>
										</div>
                }
            </div>

            <div className="login-page_spacer-bottom"/>
        </div>
    );
}

export default ThirdwebLogin;
