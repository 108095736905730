import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {useAccount, useDisconnect} from "wagmi";
import {useState} from "react";
import {EvmWallet, WalletType} from "@devour/client";
import FrameButton from "@/components/buttons/FrameButton";
import {truncateMiddle} from "@/utils/truncateMiddle";
import WalletConnectRegistryModal from "@/components/modals/WalletConnectRegistryModal";
import RemoveAWalletModal from "@/components/modals/RemoveAWalletModal";
import WalletInfoAlertIcon from "@/components/WalletInfoAlertIcon";

export default function BrandMapGoDexWallets() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);
    const {isConnected} = useAccount();
    const {disconnectAsync} = useDisconnect();
    const [showRemoveWalletModal, setShowRemoveWalletModal] = useState(false);
    const [showWalletConnectModal, setShowWalletConnectModal] = useState(false);
    const [promptedNumber, setPromptedNumber] = useState(0);

    async function toggleShowWalletConnectModal(select?: boolean): Promise<void> {
        if (isConnected && typeof disconnectAsync === "function") {
            await disconnectAsync();
        }
        setShowWalletConnectModal(s => select !== undefined
            ? select
            : !s);
    }

    function renderWallets(wallet: EvmWallet, index: number) {
        return (
            <FrameButton
                key={`wallet-${index}`}
                color="gray"
                size="pill"
                href={`https://opensea.io/${wallet.address}`}
            >
                {truncateMiddle(wallet.address, 12)}
            </FrameButton>
        );
    }

    function toggleShowRemoveWalletModal(): void {
        setShowRemoveWalletModal(s => !s);
    }

    return (
        <>
            <WalletConnectRegistryModal
                isOpen={showWalletConnectModal}
                toggle={toggleShowWalletConnectModal}
                promptedNumber={promptedNumber}
            />

            <RemoveAWalletModal
                isOpen={showRemoveWalletModal}
                onClose={toggleShowRemoveWalletModal}
            />

            <div>
                <div className="brand-map-account-drawer_header_wallets">
                    {userProfileData?.wallets?.length > 0 && userProfileData.wallets.map(renderWallets)}

                    <FrameButton
                        color="gray"
                        size="pill"
                        onClick={() => {
                            if (fullToken) {
                                toggleShowWalletConnectModal().then()
                                    .catch();
                                setPromptedNumber(promptedNumber + 1);
                            }
                        }}
                        forwardProps={{disabled: !fullToken}}
                    >
                        Add Wallet
                    </FrameButton>

                    {userProfileData?.wallets?.filter(w => w.type !== WalletType.MAGIC && w.type !== WalletType.PRIVY && w.type !== WalletType.THIRDWEB).length > 0 &&
                        <FrameButton
                            color="gray"
                            size="pill"
                            onClick={toggleShowRemoveWalletModal}
                        >
                            Remove a Wallet
                        </FrameButton>
                    }

                    {fullToken && <WalletInfoAlertIcon/>}
                </div>

                {userProfileData?.wallets?.length > 0 &&
                    <p className="go-dex-page-header_other_subtitle">
                        NFTs can take up to 15 min to refresh
                    </p>
                }
            </div>
        </>
    );
}