import {useAccount, useWriteContract, useSimulateContract} from "wagmi";
import {dpayAbi} from "@/abis/dpayAbi";
import {useState} from "react";
import {UtilsApi} from "@devour/client";

interface Props {
    externalDpay: string;
}

interface Result {
    errorMessage?: string;
    transactionHash?: string;
    status?: string;
    write: () => Promise<string>;
    prepareLoading: boolean;
}

export function useDpayTransaction(props: Props): Result {

    const [
        errorMessage,
        setErrorMessage,
    ] = useState<string>(undefined);
    const [
        transactionHash,
        setTransactionHash,
    ] = useState<string>(undefined);
    const [
        status,
        setStatus,
    ] = useState<string>(undefined);

    const account = useAccount();

    const res = useSimulateContract({
        address: import.meta.env.VITE_DPAY_TOKEN_ADDRESS_ETHEREUM as `0x${string}`,
        abi: dpayAbi,
        functionName: "transfer",
        query: {enabled: props.externalDpay != null && account.isConnected},
        args: [
            import.meta.env.VITE_DPAY_CHECKOUT_RECIPIENT_ADDRESS as `0x${string}`,
            props?.externalDpay
                ? props.externalDpay
                : BigInt(0),
        ],
    });

    const {data, isError: isPrepareError, error: prepareError, isLoading: prepareLoading} = res;

    const {
        isPending: sendDpayIsLoading,
        isSuccess: sendDpayIsSuccess,
        writeContractAsync,
        error: writeError,
    } = useWriteContract();

    async function writeWrapper(): Promise<string> {

        if (!data?.request) {
            return;
        }

        setStatus(undefined);

        if (!props.externalDpay) {
            setErrorMessage(`Invalid external ${import.meta.env.VITE_TOKEN_NAME} set for order`);
            setStatus("error");
            return;
        }

        if (sendDpayIsLoading) {
            setErrorMessage("Transaction already in flight");
            setStatus("error");
            return;
        }

        if (isPrepareError) {
            setErrorMessage(prepareError.message.split(".")[0]);
            setStatus("error");
            return;
        }

        if (!account.isConnected) {
            setErrorMessage("No wallet connected");
            setStatus("error");
            return;
        }

        if (sendDpayIsSuccess) {
            setErrorMessage(`${import.meta.env.VITE_TOKEN_NAME} already sent`);
            setStatus("error");
            return;
        }

        if (!writeContractAsync) {
            setErrorMessage("Missing write function - critical error");
            setStatus("error");
            return;
        }

        let txHash: string;

        try {
            setStatus("writing");
            txHash = await writeContractAsync((data as any)!.request);
            setStatus("write complete");

        } catch (err) {
            setErrorMessage("There was an error with the transaction, please check your wallet.");
            setStatus("error");
            return;
        }

        if (!txHash) {
            setErrorMessage("There was a problem with your order - missing transaction hash. Please try again.");
            setStatus("error");
            return;
        }

        setStatus("validating hash");

        let transactionData;

        try {
            // Initial check on the front-end, another check will happen on the back-end
            transactionData = await new UtilsApi().getDpayOnchainTransaction({id: txHash});

        } catch (err) {
            console.error("error: ", err);
            setErrorMessage("Error calling transaction data.");
            setStatus("error");
        }

        if (!transactionData.dpayRecipient ||
            transactionData.dpayRecipient !== import.meta.env.VITE_DPAY_CHECKOUT_RECIPIENT_ADDRESS) {
            setErrorMessage("Error matching transaction hash. Please try again, and contact support if issue persists.");
            setStatus("error");
            return;
        }

        setStatus("success");
        setTransactionHash(txHash);

        return txHash;
    }

    let _errorMessage: string;

    if (writeError) {
        _errorMessage = writeError.message.split(".")[0];
    } else if (errorMessage) {
        _errorMessage = errorMessage;
    }

    return {
        errorMessage: _errorMessage,
        write: writeWrapper,
        transactionHash: transactionHash,
        status: status,
        prepareLoading: prepareLoading,
    };

}