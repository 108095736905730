import {ReactElement, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {EvmWallet, Token, UsersApi, WalletType} from "@devour/client";
import FrameButton from "./buttons/FrameButton";
import {truncateMiddle} from "../utils/truncateMiddle";
import {FiTrash} from "react-icons/fi";
import {addError, decrementLoading, incrementLoading, updateCurrentUser} from "../redux/meta/metaActions";
import getConfig from "../utils/getConfig";
import WalletRemovalSuccessModal from "./modals/WalletRemovalSuccessModal";
import {useQueryClient} from "@tanstack/react-query";
import {USE_GET_USER_PROFILE_KEY} from "@/hooks/useGetUserProfile";

interface StateProps {
    fullToken: Token;
}

interface Props {
    wallet: EvmWallet
    onDone: () => void;
}

function RemoveWalletButton(props: RemoveWalletButtonProps): ReactElement {
    const queryClient = useQueryClient();
    const [showSuccess, setShowSuccess] = useState(false);

    async function removeWallet(): Promise<void> {
        props.dispatch(incrementLoading());
        try {
            await new UsersApi(getConfig(props.fullToken)).removeWallet({
                removeWalletRequestBody: {
                    walletId: props.wallet.id,
                },
            });

            const userRes = await new UsersApi(getConfig(props.fullToken)).getProfile();

            props.dispatch(updateCurrentUser(userRes));

            queryClient.setQueryData([
                USE_GET_USER_PROFILE_KEY,
                props.fullToken?.id,
            ], userRes);

            setShowSuccess(true);
        } catch (e) {
            props.dispatch(await addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    function handleDismissSuccessModal(): void {
        setShowSuccess(false);
        props.onDone();
    }

    return (
        <>
            <WalletRemovalSuccessModal
                isOpen={showSuccess}
                onClose={handleDismissSuccessModal}
            />

            <div className="remove-wallet-button">
                <FrameButton
                    color="gray"
                    size="normal"
                    href={`https://opensea.io/${props.wallet.address}`}
                >
                    {truncateMiddle(props.wallet.address, 16)}
                </FrameButton>

                {props.wallet.type !== WalletType.MAGIC && props.wallet.type !== WalletType.PRIVY && props.wallet.type !== WalletType.THIRDWEB &&
                <FrameButton
					    color="danger"
					    size="icon"
					    leftIcon={FiTrash}
					    onClick={removeWallet}
                />
                }
            </div>
        </>
    );
}

function connector() {
    return connect((store: IStore, props: Props): StateProps & Props => {
        return {
            fullToken: store.authStore.fullToken,
            ...props,
        };
    });
}

type RemoveWalletButtonProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(RemoveWalletButton);
