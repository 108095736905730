import {AnchorHTMLAttributes, ButtonHTMLAttributes, ReactElement, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../redux/defaultStore";
import FrameButton from "../components/buttons/FrameButton";
import {addError} from "../redux/meta/metaActions";
import { magic } from "../utils/magic";
import * as ethers from "ethers";
import {useImportWallet, usePrivy, useWallets} from "@privy-io/react-auth";

function MagicMigration(): ReactElement {
    const dispatch = useDispatch();
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const [privateKey, setPrivateKey] = useState<string>("");
    const {importWallet} = useImportWallet();
    const {wallets} = useWallets();
    const {exportWallet} = usePrivy();

    async function loginWithOtp(): Promise<void> {
        console.log("loginwithotp");
        try {
            const magicCredential = await magic.auth.loginWithEmailOTP({
                email: currentUser.user.email,
            });
            /*
             * const provider = new ethers.BrowserProvider(magic.rpcProvider);
             * const signer = await provider.getSigner();
             *
             * const destination = "0x9b2f74D0Cde2752AfD3e76b67f9AaEB9C6413391";
             * const amount = ethers.parseEther("1.0");
             *
             * const tx = await signer.sendTransaction({
             *     to: destination,
             *     value: amount,
             * });
             * console.log("tx", tx);
             * const receipt = await tx.wait();
             * console.log("receipt", receipt);
             */
        } catch (err) {
            console.log("error", err);
            dispatch(await addError(err));
        }
    }

    async function importWalletToPrivy(): Promise<void> {
        try {
            const wallet = await importWallet({
                privateKey: privateKey,
            });
            console.log("Wallet imported successfully:", wallet);
            alert(`Wallet imported successfully: ${wallet.address}`);
            setPrivateKey("");
        } catch (error) {
            console.error("Failed to import wallet:", error);
            alert(`Failed to import wallet: ${error.message}`);
        }

    }

    return (
        <>
            <h1>
                Migrate Magic wallet to Privy
            </h1>
            <ol>
                {/* <li>*/}
                {/*    <FrameButton*/}
                {/*        <ButtonHTMLAttributes<HTMLButtonElement>>*/}
                {/*        color={"purple"}*/}
                {/*        size="normal"*/}
                {/*        onClick={loginWithOtp}*/}
                {/*    >*/}
                {/*        Login to Magic*/}
                {/*    </FrameButton>*/}
                {/* </li>*/}
                <li>
                    <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        color="purple"
                        size="narrow"
                        href="https://reveal.magic.link/devourgo"
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Reveal Private Key
                    </FrameButton>
                </li>
                <li>
                    <input
                        type="password"
                        value={privateKey}
                        onChange={(e) => setPrivateKey(e.target.value)}
                        placeholder="Enter your private key"
                    />
                </li>
                <li>
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color={"purple"}
                        size="normal"
                        onClick={importWalletToPrivy}
                    >
                        Import wallet to Privy
                    </FrameButton>
                </li>
            </ol>
            <h2>
                Privy key export
            </h2>
            <ul>
                {wallets.map(wallet => (
                    <li key={wallet.address}>
                        Address: {wallet.address}<br/>
                        Connector type: {wallet.connectorType}<br/>
                        Imported: {wallet.imported.toString()}<br/>
                        <FrameButton
                            color="purple"
                            size="narrow"
                            // onClick={exportWallet}
                            onClick={async() => {
                                await exportWallet({address: wallet.address});
                            }}
                        >
                            Export {wallet.address} Private Key
                        </FrameButton>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default MagicMigration;
