import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useState,
} from "react";
import FrameButton from "../buttons/FrameButton";
import {validateEmail} from "@/utils/validateEmail";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";

interface Props {
    initialEmail?: string;
    onSubmit: (email: string) => void;
}

function LoginEmail(props: Props): ReactElement {
    const { isOnOverwolf } = useOverwolfInterop();
    const [email, setEmail] = useState<string>(props.initialEmail || "");

    const emailOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value);
    };

    function loginWithEmail(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        props.onSubmit(email);
    }


    return (
        <form onSubmit={loginWithEmail}>
            <div className="login-page_content_email-container">
                <label>Email</label>
                <input
                    type="email"
                    required
                    placeholder="Enter your email address"
                    value={email}
                    onChange={emailOnChange}
                />
            </div>

            <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                color={!isOnOverwolf ? "purple" : "purple-blue-gradient-shadow"}
                size="normal"
                className="login-page_content_login-button"
                forwardProps={{
                    type: "submit",
                    disabled: !validateEmail(email),
                }}
            >
								Continue
            </FrameButton>
        </form>

    );
}

export default LoginEmail;
