import {
    ButtonHTMLAttributes,
    ChangeEventHandler,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from "react";
import FrameButton from "../buttons/FrameButton";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import FrameOneCheckbox from "@/components/inputs/FrameOneCheckbox";

interface Props {
    email: string;
    onSubmit: (referralCode?: string) => void;
    initialReferralCode?: string;
}

function LoginRegistration(props: Props): ReactElement {
    const [confirmations, setConfirmations] = useState({
        age: false,
        terms: false,
    });
    const [referralCode, setReferralCode] = useState<string>("");
    const {isOnOverwolf} = useOverwolfInterop();
    const userConfirmedTerms = confirmations.age && confirmations.terms;

    useEffect(() => {
        setReferralCode(props.initialReferralCode);
    }, [props.initialReferralCode]);

    const refCodeOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setReferralCode(e.target.value);
    };

    function submitSignUp(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        props.onSubmit(referralCode);
    }

    return (
        <form onSubmit={submitSignUp}>
            <div className="login-page_content_email-container">
                <label>Email</label>
                <input
                    type="email"
                    required
                    placeholder="Enter your email address"
                    value={props.email}
                    disabled={true}
                />
            </div>

            <div className="login-page_content_referral-container">
                <label>Referral code</label>
                <input
                    placeholder="Enter your referral code (optional)"
                    value={referralCode}
                    onChange={refCodeOnChange}
                />
            </div>


            <div className="login-page_content_confirm">
                <div className="login-page_content_confirm_field">
                    <FrameOneCheckbox
                        background="purple"
                        checked={confirmations.age}
                        onToggle={() => {
                            setConfirmations({
                                ...confirmations,
                                age: !confirmations.age,
                            });
                        }}
                    />
                    <span className="login-page_content_confirm_field_text">
                                I confirm that I am 18 years of age or older.
                    </span>
                </div>
                <div className="login-page_content_confirm_field">
                    <FrameOneCheckbox
                        background="purple"
                        checked={confirmations.terms}
                        onToggle={() => {
                            setConfirmations({
                                ...confirmations,
                                terms: !confirmations.terms,
                            });
                        }}
                    />
                    <span className="login-page_content_confirm_field_text">
                        I have read, understood, and agree to the{" "}
                        <a href="/terms-of-use">DevourGO Terms of Use</a> ,{" "}
                        <a href="/privacy-policy">Privacy Policy</a>, and{" "}
                        <a href="/cookie-policy">Cookie Policy</a> .
                    </span>
                </div>
            </div>

            <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                color={!isOnOverwolf ? "purple" : "purple-blue-gradient-shadow"}
                size="normal"
                className="login-page_content_login-button"
                forwardProps={{
                    type: "submit",
                    disabled: !userConfirmedTerms,
                }}
            >
								Create Account
            </FrameButton>
        </form>
    );
}

export default LoginRegistration;
