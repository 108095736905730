import {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    OnboardingApi,
    UsersApi,
    OWUserSessionEventType,
} from "@devour/client";
import {login} from "@/redux/auth/authActions";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {usePrivy, User as PrivyUser} from "@privy-io/react-auth";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateAnonymousSpin,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig, {getPrivyConfig} from "@/utils/getConfig";
import {removeReferralCode} from "@/redux/affiliateReferrals/affiliateActions";
import {logOverwolfUserEvent} from "@/utils/logOverwolfUserEvent";
import * as Sentry from "@sentry/react";
import getAnonymousSpin from "@/utils/getAnonymousSpin";
import {AnonymousSpinModalShownKey} from "@/components/modals/GoVipSpin2WinModal";
import useOnLogout from "@/hooks/useOnLogout";

interface OnLoginComplete {
    isExistingUser: boolean;
}

/**
 * Hook to handle post-login functionality
 */
export default function useOnAuthPrivy() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const signedUrlKey = searchParams.get("suk");
    const {sendMessageToOW, isOnOverwolf} = useOverwolfInterop();
    const {getAccessToken} = usePrivy();
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    const {devourLogout} = useOnLogout();

    async function onLoginComplete(user: PrivyUser, referralCode?: string): Promise<OnLoginComplete> {
        if (!user || isExecuting) {
            return;
        }

        setIsExecuting(true);
        dispatch(incrementLoading());

        try {
            const privyAccessToken = await getAccessToken();
            if (!privyAccessToken) {
                throw new Error("Unable to get token.");
            }

            const existingRes = await new UsersApi(getConfig()).validateUserExistence({
                email: user.email.address,
            });
            if (!existingRes._exists) {
                await submitSignUp(privyAccessToken, referralCode);
            }

            // call the login api with the Privy access key, if the API returns 200 with a token, then log the user in as normal
            const res = await new UsersApi(getPrivyConfig(privyAccessToken)).privyLogin();

            // grab the profile to save into redux
            const userRes = await new UsersApi(getConfig(res.token)).getProfile();

            //  save the token and profile into redux
            sendMessageToOW({
                type: "de:data",
                isLogin: true,
                payload: {
                    token: res.token,
                    userData: userRes,
                },
            });
            dispatch(login(res.token));
            dispatch(updateCurrentUser(userRes));
            dispatch(removeReferralCode());

            if (isOnOverwolf) {
                void logOverwolfUserEvent(OWUserSessionEventType.LOGIN);
            }

            Sentry.setUser({
                email: userRes.user.email,
            });
            Sentry.setContext("userInfo", userRes);

            if (signedUrlKey) {
                await new OnboardingApi(getConfig(res.token)).mergeUserWithSignedUrlKey({
                    signedUrlKey: signedUrlKey,
                });
            }

            const anonymousSpin = getAnonymousSpin();
            if (anonymousSpin) {
                dispatch(updateAnonymousSpin(undefined));
                window[AnonymousSpinModalShownKey] = false;
            }

            return {
                isExistingUser: existingRes._exists,
            };
        } catch (err) {
            devourLogout();
            dispatch(await addError(err));
        } finally {
            dispatch(decrementLoading());
            setIsExecuting(false);
        }

    }

    async function submitSignUp(privyAccessToken: string, referralCode?: string) {
        // call the login api with the magic_credential, if the API returns 200 with a token, then log the user in as normal
        const anonymousSpin = getAnonymousSpin();
        const res = await new UsersApi(getPrivyConfig(privyAccessToken)).register({
            registerBody: {
                anonymousSpinValue: anonymousSpin?.spinValue,
                referralCode: referralCode,
            },
        });

        if (signedUrlKey) {
            await new OnboardingApi(getConfig(res.token)).mergeUserWithSignedUrlKey({
                signedUrlKey: signedUrlKey,
            });
        }
    }

    return {onLoginComplete};
}

