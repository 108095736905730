import { StrictMode } from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux";
import App from "./App";
import "./style/index.scss";

import {WagmiProvider } from "wagmi";
import { createAppKit } from "@reown/appkit/react";
import { arbitrum, base, mainnet, polygon } from "@reown/appkit/networks";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import {initSentry} from "./utils/sentry";
import initHotjar from "@/utils/hotjar";

const projectId = "726737766a01cd47d7e79d8a3352e859";

const metadata = {
    name: "DevourGo",
    description: "DevourGo",
    url: "https://devourgo.io",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const wagmiAdapter = new WagmiAdapter({
    networks: [mainnet, arbitrum, base, polygon],
    projectId,
});

createAppKit({
    adapters: [wagmiAdapter],
    networks: [mainnet, arbitrum, base, polygon],
    metadata: metadata,
    projectId,
    features: {
        analytics: true,
    },
});

const metaStore = localStorage.getItem("persist:meta");
if (!metaStore || metaStore?.includes("dark")) {
    document.documentElement.setAttribute("data-theme", "dark");
}
createRoot(document.getElementById("root")).render(<StrictMode>
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            {/* @ts-ignore */}
            <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                <App/>
            </WagmiProvider>

        </PersistGate>
    </Provider>
</StrictMode>);

initSentry();

initHotjar();

