import {ReactElement} from "react";
import useOnAuthThirdweb from "@/hooks/useOnAuthThirdweb";
import {useAutoConnect} from "thirdweb/react";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient.ts";

/**
 * Auto login if user refresh or close their browser.
 * @constructor
 */
function ThirdwebSessionManager(): ReactElement {
    const {onLoginComplete} = useOnAuthThirdweb();

    useAutoConnect({
        client: thirdwebAuthClient,
        // accountAbstraction,
        // wallets,
        onConnect: (wallet) => {
            const account = wallet?.getAccount();
            if (account) {
                void onLoginComplete(account);
            }
        },
        // timeout,
    });

    return null;
}

export default ThirdwebSessionManager;
