import {ReactElement, useEffect} from "react";
import { usePrivy} from "@privy-io/react-auth";
import useOnAuthPrivy from "@/hooks/useOnAuthPrivy";

/**
 * Auto login if user refresh or close their browser.
 * @constructor
 */
function PrivySessionManager(): ReactElement {
    const {authenticated, user, ready} = usePrivy();
    const {onLoginComplete} = useOnAuthPrivy();

    /**
     * Only trigger this on ready load.
     * If user logs in mid-session, the login callback will also execute the completion function.
     */
    useEffect(() => {
        if (ready && authenticated && user) {
            void onLoginComplete(user);
        }
    }, [ready]);

    return null;
}

export default PrivySessionManager;
