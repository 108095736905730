import {ReactElement, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {decrementModalCount, incrementModalCount} from "@/redux/meta/metaActions";
import classNames from "classnames";
import {IoClose, IoExitOutline} from "react-icons/io5";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import {getUserFullName} from "@/utils/getUserFullName";
import {RiPencilLine} from "react-icons/ri";
import UserEditEmail from "@/components/UserEditEmail.tsx";
import UserPaymentMethods from "@/components/UserPaymentMethods";
import FrameButton from "@/components/buttons/FrameButton";
import EditProfilePictureModal from "@/components/modals/EditProfilePictureModal";
import GoDexPageDpay from "@/components/GoDexPageDpay";
import {AiFillCheckCircle} from "react-icons/ai";
import ReferralCodeGeneration from "@/components/ReferralCodeGeneration";
import AccountDeletionPanel from "@/components/AccountDeletionPanel";
import BrandMapGoDexWallets from "@/components/brands/BrandMapGoDexWallets";
import {useGetBrandMap} from "@/hooks/useGetBrandMap";
import {useParams} from "react-router";
import {BrandMapContext, BrandMapStates} from "@/pages/brandMap/context/BrandMapContext";
import useOnLogout from "@/hooks/useOnLogout";

interface Props {
    isOpen: boolean;
    toggle: () => void;
}

export default function BrandMapAccountDrawer(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {slug} = useParams<{ slug: string }>();
    const {devourLogout} = useOnLogout();

    const {data: userProfileData} = useGetUserProfile(fullToken);
    const {refetch: refetchBrandMap} = useGetBrandMap(slug);
    const {setBrandMapState, setSelectedRestaurant, setSelectedPromo} = useContext(BrandMapContext);

    const [count, setCount] = useState<number>(0);
    const [editProfileModal, setEditProfileModal] = useState<boolean>(false);

    useEffect(() => {
        /*
         * Need to raise z-index of top header bar when account drawer is open
         * so that the mobile drawer is hidden behind account drawer.
         */
        const promoHeader = document.querySelector(".restaurant-promo-header");

        if (props.isOpen) {
            dispatch(incrementModalCount());
            document.getElementById("drift-widget-wrapper")
                ?.classList.add("drift-frame-controller-hide");

            promoHeader.classList.add("is-open");
        }

        return () => {
            if (props.isOpen) {
                dispatch(decrementModalCount());
                document.getElementById("drift-widget-wrapper")?.classList
                    .remove("drift-frame-controller-hide");

                promoHeader.classList.remove("is-open");
            }
        };
    }, [props.isOpen]);

    function getProfileImageUrl(): string {
        if (userProfileData?.user?.gravatar) {
            const versionParam = userProfileData.user.profilePicture
                ? `?v=${count}`
                : "";
            return userProfileData.user.gravatar + versionParam;
        }
        return `${import.meta.env.VITE_CDN_URL}/images/DevourGO-Site-Icon.webp`;
    }

    function toggleEditProfileModal() {
        setEditProfileModal(false);
    }

    function handleLogOut() {
        devourLogout();
        void refetchBrandMap();
        props.toggle();
        setBrandMapState(BrandMapStates.NOT_INITIATED);
        setSelectedRestaurant(undefined);
        setSelectedPromo(undefined);
    }

    return (
        <>
            {fullToken && <EditProfilePictureModal
                isOpen={editProfileModal}
                onClose={toggleEditProfileModal}
                onProfilePictureUpdate={() => setCount(count + 1)}
            />}
            <div
                className={classNames("brand-map-account-drawer_overlay", {
                    "is-active": props.isOpen,
                })}
                onClick={props.toggle}
            />
            <div
                className={classNames("brand-map-account-drawer", {
                    "is-active": props.isOpen,
                })}
            >
                <button
                    className="brand-map-account-drawer_toggle-btn"
                    onClick={props.toggle}
                >
                    <IoClose size="1.625rem"/>
                </button>
                <div className="brand-map-account-drawer_header">
                    <div className="brand-map-account-drawer_header_img-con">
                        <img
                            src={getProfileImageUrl()}
                            alt="User Profile"
                        />
                    </div>
                    <h4 className="brand-map-account-drawer_header_name">
                        {fullToken ? getUserFullName(userProfileData?.user) : "DevourGO Guest"}
                        <RiPencilLine size="1.2rem" onClick={() => setEditProfileModal(true)}/>
                    </h4>
                    <BrandMapGoDexWallets/>
                </div>

                <div className="brand-map-account-drawer_dpay">
                    <GoDexPageDpay showWithdrawButton={true}/>
                </div>

                <div className="brand-map-account-drawer_user-edit">
                    <UserEditEmail/>
                </div>

                <div className="brand-map-account-drawer_referrals">
                    <h3>Refer-A-Friend</h3>
                    <div className="brand-map-account-drawer_referrals_copy-container">
                        <AiFillCheckCircle className="sign-up_content_referral-link-container_header_icon"/>
                        <p>
                            Give $30 in discounts to your friends, and get $20 in DPAY when they place their first
                            order!
                        </p>
                    </div>

                    <p>Grab your referral code or link below to share with friends to earn!</p>
                    <p className="referral-code-generation_input-container_text">
                        *First order must be over $15 to qualify
                    </p>
                    <ReferralCodeGeneration/>
                </div>

                <div className="brand-map-account-drawer_payment-methods">
                    <h3>Payment Methods</h3>
                    <UserPaymentMethods/>
                </div>

                <div className="brand-map-account-drawer_deactivate">
                    <h3>Manage Account</h3>
                    <AccountDeletionPanel/>
                </div>

                <div className="divider"/>

                <div className="brand-map-account-drawer_bottom">
                    <h5>Sign out of DevourGO</h5>
                    <p className="brand-map-account-drawer_bottom_disclaimer">
                        Logging out will require you to sign in again with new login code to access your account.
                    </p>
                    <FrameButton
                        color="purple-outline"
                        size="normal"
                        rightIcon={IoExitOutline}
                        className="brand-map-account-drawer_bottom_logout"
                        onClick={handleLogOut}
                    >
                        Log Out
                    </FrameButton>
                </div>


            </div>
        </>
    );
}
