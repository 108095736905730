import {AnchorHTMLAttributes, ReactElement, useState} from "react";
import FrameButton from "../buttons/FrameButton";
import {useDispatch, useSelector} from "react-redux";
import Toast from "@/components/Toast";
import { FaEthereum } from "react-icons/fa";
import FrameOneModal from "@/components/modals/modalComponents/FrameOneModal";
import FrameModalHeader from "@/components/modals/modalComponents/FrameModalHeader";
import FrameModalBody from "@/components/modals/modalComponents/FrameModalBody";
import {IStore} from "@/redux/defaultStore";
import {toggleLoginOpen, toggleMagicWalletsModal} from "@/redux/meta/metaActions";
import useThemePreference from "@/hooks/useThemePreference";
import useOnLogout from "@/hooks/useOnLogout";
import {useWallets} from "@privy-io/react-auth";
import {UserPill} from "@privy-io/react-auth/ui";

function SelectMagicWalletModal(): ReactElement {
    const {getThemedImageUrl} = useThemePreference();
    const [
        showMagicErrorToast,
        setShowMagicErrorToast,
    ] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {devourLogout} = useOnLogout();
    const {wallets} = useWallets();
    const embeddedWallet = wallets.find((wallet) => wallet.walletClientType === "privy" && wallet.connectorType === "embedded");
    const isModalShown = useSelector((store: IStore) => store.metaStore.isMagicWalletsModalActive);

    function handleMagicErrorToastDismissal() {
        devourLogout();
        setShowMagicErrorToast(false);
        dispatch(toggleLoginOpen(true));
    }

    function renderPolygonIcon(): ReactElement {
        return (
            <img
                src={getThemedImageUrl(import.meta.env.VITE_CDN_URL + "/images/polygon-logo.png")}
                alt="Polygon Logo"
            />
        );
    }

    function toggleModal(): void {
        dispatch(toggleMagicWalletsModal(!isModalShown));
    }

    return (
        <>
            <Toast
                forceWidth="40rem"
                message="Uh oh! There was an issue with your active session. Please log in again. If this problem persists, contact support."
                isOpen={showMagicErrorToast}
                showButton={true}
                buttonMessage="Dismiss"
                buttonFunction={handleMagicErrorToastDismissal}
                onDismiss={handleMagicErrorToastDismissal}
            />
            <FrameOneModal
                isOpen={isModalShown}
                toggle={toggleModal}
                contentClassName="select-magic-wallet-modal"
            >
                <FrameModalHeader
                    title="Select Wallet"
                    toggle={toggleModal}
                />

                <FrameModalBody className="select-magic-wallet-modal_body">

                    <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        color="purple-outline"
                        size="large"
                        leftIcon={FaEthereum}
                        href={`https://etherscan.io/address/${embeddedWallet?.address}`}
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Ethereum Wallet
                    </FrameButton>

                    <FrameButton
                        <AnchorHTMLAttributes<HTMLAnchorElement>>
                        color="purple-outline"
                        size="large"
                        leftIcon={renderPolygonIcon}
                        href={`https://polygonscan.com/address/${embeddedWallet?.address}`}
                        forwardProps={{
                            target: "_blank",
                            rel: "noopener noreferrer",
                        }}
                    >
                        Polygon Wallet
                    </FrameButton>

                    <UserPill />

                </FrameModalBody>

            </FrameOneModal>
        </>
    );
}

export default SelectMagicWalletModal;
