import {AnchorHTMLAttributes, ReactElement, useEffect, useState} from "react";
import FrameButton from "./buttons/FrameButton";
import CopiedInput from "@/components/inputs/ReferralCopiedInput";
import {magicSolana} from "@/utils/magic";
import {MdOutlineContentCopy} from "react-icons/md";
import {isGoNative} from "@/utils/isGoNative";
import {usePrivy} from "@privy-io/react-auth";
import {useGate} from "statsig-react";
import ThirdwebConnectButton from "@/components/auth/ThirdwebConnectButton.tsx";

function AccountMagicWalletPrivateKeyExportPanel(): ReactElement {
    const {exportWallet} = usePrivy();
    const { value: thirdwebAuth } = useGate("thirdweb_auth");

    const [
        revealSolanaAddress,
        setRevealSolanaAddress,
    ] = useState<boolean>(false);
    const [
        solanaAddress,
        setSolanaAddress,
    ] = useState<string>("");
    const [
        solanaCopied,
        setSolanaCopied,
    ] = useState<boolean>(false);

    useEffect(() => {
        getSolanaAddress().then((address) => {
            setSolanaAddress(address);
        });
    }, []);

    const getSolanaAddress = async () => {
        const metadata = await magicSolana.user.getMetadata();
        return metadata.publicAddress;
    };

    const copySolanaPublicKey = () => {
        setSolanaCopied(true);
        setTimeout(() => {
            setSolanaCopied(false);
        }, 3000);
        if (isGoNative()) {
            // @ts-ignore
            gonative.clipboard.set({"data": solanaAddress}); // for app to copy to clipboard
        } else {
            navigator.clipboard.writeText(solanaAddress);
        }
    };

    return (
        <>
            <h3>DevourGO Wallet</h3>
            <div className="acc-magic-export-panel">
                <div className="acc-magic-export-panel_text">
                    <p>
                        <strong>
                            Magic Private key
                        </strong>
                    </p>
                    <p className="acc-magic-export-panel_text_description">
                        Click the button to reveal your DevourGO Magic wallet's private key
                    </p>
                </div>

                <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    color="purple"
                    size="narrow"
                    href="https://reveal.magic.link/devourgo"
                    forwardProps={{
                        target: "_blank",
                        rel: "noopener noreferrer",
                    }}
                >
                    Reveal Private Key
                </FrameButton>
            </div>
            {thirdwebAuth ? (
                <div className="acc-magic-export-panel">
                    <div className="acc-magic-export-panel_text">
                        <p>
                            <strong>
                                Thirdweb Wallet
                            </strong>
                        </p>
                        <p className="acc-magic-export-panel_text_description">
                            Click the button to export your DevourGO wallet's private key
                        </p>
                    </div>

                    <ThirdwebConnectButton />
                </div>
            ) : (
                <div className="acc-magic-export-panel">
                    <div className="acc-magic-export-panel_text">
                        <p>
                            <strong>
                                Privy key
                            </strong>
                        </p>
                        <p className="acc-magic-export-panel_text_description">
                            Click the button to export your DevourGO wallet's private key
                        </p>
                    </div>

                    <FrameButton
                        color="purple"
                        size="narrow"
                        onClick={exportWallet}
                        // onClick={async() => {
                        //     const importedWallet = wallets.find((wallet) => wallet.walletClientType === "privy" && wallet.imported);
                        //     await exportWallet({address: importedWallet?.address});
                        // }}
                    >
                        Export Privy Key
                    </FrameButton>
                </div>
            )}
            <div className="acc-magic-export-panel">
                <div className="acc-magic-export-panel_text">
                    <p>
                        <strong>
                            Solana Wallet Address
                        </strong>
                    </p>
                    <p className="acc-magic-export-panel_text_description">
                        {revealSolanaAddress
                            ? <div className="input-container">
                                <CopiedInput
                                    className="referral-code-generation_input-container_input"
                                    disabled={true}
                                    value={solanaAddress}
                                    isCopiedVisible={solanaCopied}
                                />
                                <FrameButton
                                    color="gray"
                                    size="normal"
                                    leftIcon={MdOutlineContentCopy}
                                    onClick={() => copySolanaPublicKey()}>
                                    Copy
                                </FrameButton>
                            </div>
                            : "Click the button to reveal your Solana wallet's public key"
                        }

                    </p>
                </div>
                {!revealSolanaAddress &&
                <FrameButton
                    <AnchorHTMLAttributes<HTMLAnchorElement>>
                    color="purple"
                    size="narrow"
                    onClick={() => setRevealSolanaAddress(true)}
                >
                        Reveal Public Key
                </FrameButton>}
            </div>
        </>
    );
}

export default AccountMagicWalletPrivateKeyExportPanel;