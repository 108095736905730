import {ReactElement, useState} from "react";
import {useActiveAccount} from "thirdweb/react";
import RedeemLootboxButton from "@/components/RedeemLootboxButton";
import {base} from "thirdweb/chains";
import {TransactionReceipt} from "viem";

const contractAddress = "0x365052ff440022e7f0f4a581E268861Da32d4C4F";

function ThirdwebBurnTest(): ReactElement {
    const account = useActiveAccount();
    const [nftId, setNftId] = useState<number>(0);
    const [transactionHash, setTransactionHash] = useState<string>("");

    function onTransactionConfirmed(receipt: TransactionReceipt) {
        alert(`Confirmed: ${receipt.transactionHash}`);
        setTransactionHash(receipt.transactionHash);
    }

    if (!account) {
        return null;
    }

    return (
        <>
            <h1>
								Thirdweb Gasless Burn NFT
            </h1>

            <p>
								Your wallet address: {account.address}
            </p>
            <p>
								Contract Address: {contractAddress}
            </p>
            <div>
								NFT ID<br/>
								Find ID on{" "}
                <a
                    href={`https://basescan.org/address/${account.address}#nfttransfers`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
										https://basescan.org/address/{account.address}#nfttransfers
                </a>
                <input
                    type="number"
                    placeholder="NFT ID..."
                    value={nftId}
                    onChange={(e) => setNftId(Number(e.target.value))}
                    step={1}
                />
            </div>
            <RedeemLootboxButton
                nftId={nftId}
                contractAddress={contractAddress}
                chain={base}
                onTransactionConfirmed={onTransactionConfirmed}
            />
            <p>
								Transaction Hash:
                {transactionHash &&
										<a
										    href={`https://basescan.org/tx/${transactionHash}`}
										    target="_blank"
										    rel="noopener noreferrer"
										>
												https://basescan.org/tx/{transactionHash}
										</a>
                }
            </p>
        </>
    );
}

export default ThirdwebBurnTest;
