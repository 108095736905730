import {usePrivy} from "@privy-io/react-auth";
import {useDispatch} from "react-redux";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {magic} from "@/utils/magic";
import {logout} from "@/redux/auth/authActions";
import {useActiveWallet, useDisconnect} from "thirdweb/react";

export default function useOnLogout() {
    const { sendMessageToOW } = useOverwolfInterop();
    const dispatch = useDispatch();
    const { logout: privyLogout} = usePrivy();
    const { disconnect } = useDisconnect();
    const connectedWallet = useActiveWallet();

    function devourLogout() {
        console.log("devour-logout");
        dispatch(logout());
        sendMessageToOW({type: "de:logout"});
        if (connectedWallet) {
            disconnect(connectedWallet);
        }
        void privyLogout();
        void magic.user.logout();
    }

    return {devourLogout};
}
