import {ReactElement, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import getConfig from "../../../utils/getConfig";
import {NftPurchaseRequestBody, NftPurchaseUpsert, ThirdwebApi} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import {magicPolygon} from "@/utils/magic";
import Toast from "../../Toast";
import {useNavigate} from "react-router-dom";
import {useGetTransactions} from "@/hooks/useGetTransactions";
import PaymentFailedModal from "@/components/modals/PaymentFailedModal";
import MintIndustryPassModalSuccess from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalSuccess";
import MintIndustryPassModalInProgress from "@/components/modals/mintIndustryPassModal/MintIndustryPassModalInProgress";
import {PaymentStates} from "@/components/modals/mintIndustryPassModal/PaymentStates";
import { useOnLogin } from "@/hooks/useOnLogin";
import useOnLogout from "@/hooks/useOnLogout";

function MintIndustryPassModal(): ReactElement {

    const [
        paymentState,
        setPaymentState,
    ] = useState<PaymentStates>(PaymentStates.SELECT_PAYMENT);
    const [
        nftPurchase,
        setNftPurchase,
    ] = useState<NftPurchaseUpsert>();
    const [
        showMagicErrorToast,
        setShowMagicErrorToast,
    ] = useState<boolean>(false);
    const [
        showPaymentFailed,
        setShowPaymentFailed,
    ] = useState<boolean>(false);
    const navigate = useNavigate();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const dispatch = useDispatch();
    const {devourLogout} = useOnLogout();

    const {refetch: refetchTransactionData} = useGetTransactions(fullToken, currentUser?.user?.id);


    useEffect(() => {
        // Fetch next claimable NFT information
        void getClaimableNft({});
    }, []);

    useOnLogin(() => {
        void getClaimableNft({});
    });

    useEffect(() => {
        if (paymentState === PaymentStates.SELECT_PAYMENT) {
            resetStates();
        }
    }, [paymentState]);

    function resetStates(): void {
        void getClaimableNft({
            fiatInDpay: 0,
            paymentMethodId: "",
        });
        setPaymentState(PaymentStates.SELECT_PAYMENT);
    }

    async function getClaimableNft(values: NftPurchaseRequestBody): Promise<void> {
        const isMagicActive = await checkMagicActive();
        if (!isMagicActive) {
            return;
        }
        dispatch(incrementLoading());
        try {
            const res = await new ThirdwebApi(getConfig()).upsertNftPurchase({
                nftPurchaseRequestBody: values,
            });
            setNftPurchase(res);
        } catch (e) {
            dispatch(await addError(e));
            // Reset form value to previous value
        }
        dispatch(decrementLoading());
    }

    async function checkMagicActive(): Promise<boolean> {
        return magicPolygon.user.isLoggedIn();
    }

    function handleMagicErrorToastDismissal() {
        setShowMagicErrorToast(false);
        devourLogout();
        navigate("/log-in");
    }

    async function onSubmit(): Promise<void> {
        const isMagicPolygonActive = await checkMagicActive();
        if (!isMagicPolygonActive) {
            return;
        }
        dispatch(incrementLoading());
        try {
            const res = await new ThirdwebApi(getConfig()).submitNftPurchase({
                id: nftPurchase.id,
            });
            if (res.nextAction) {
                window.location.href = res.nextAction;

            } else {
                setPaymentState(PaymentStates.SUCCESS);
                await refetchTransactionData();
            }
        } catch (e) {
            setShowPaymentFailed(true);
        }
        dispatch(decrementLoading());
    }

    function renderContents(): ReactElement {
        if (paymentState !== PaymentStates.SUCCESS) {
            return (
                <MintIndustryPassModalInProgress
                    nftPurchase={nftPurchase}
                    getClaimableNft={getClaimableNft}
                    setPaymentState={setPaymentState}
                    onSubmit={onSubmit}
                    paymentState={paymentState}
                />
            );
        }
        return (
            <MintIndustryPassModalSuccess
                nftPurchase={nftPurchase}
                resetStates={resetStates}
            />
        );

    }

    return (
        <>
            <Toast
                forceWidth="30rem"
                message="Uh oh! Looks like your active session expired. Please log in again."
                isOpen={showMagicErrorToast}
                onDismiss={handleMagicErrorToastDismissal}
            />
            <PaymentFailedModal
                isOpen={showPaymentFailed}
                toggle={() => setShowPaymentFailed(!showPaymentFailed)}
            />
            <div className="the-industry-pass-page_purchase_modal-template">
                <div className="mint-industry-credit-card">
                    <div className="mint-industry-credit-card_body">
                        {renderContents()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MintIndustryPassModal;
