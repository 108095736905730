import {Configuration, Token} from "@devour/client";
import {store} from "@/redux";

export default function getConfig(fullToken?: Token, basePath?: string): Configuration {
    const _fullToken = fullToken || store.getState().authStore.fullToken;

    const headers = _fullToken?.token
        ? {headers: {authorization: `Bearer ${_fullToken?.token}`}}
        : {};
    const base = basePath
        ? {basePath}
        : {};
    return new Configuration({
        ...headers,
        ...base,
    });
}

export function getMagicConfig(magicCredential: string): Configuration {
    return new Configuration({
        headers: {
            "authorization": `Bearer ${magicCredential}`,
        },
    });
}

export function getPrivyConfig(privyAccessKey: string): Configuration {
    return new Configuration({
        headers: {
            "authorization": `Bearer ${privyAccessKey}`,
        },
    });
}
