import {applyMiddleware, combineReducers, compose, createStore, Reducer, Store, StoreEnhancer} from "redux";
import promiseMiddleware from "redux-promise";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import defaultStore, {IStore} from "./defaultStore";
import metaReducer from "./meta/metaReducer";
import authReducer from "./auth/authReducer";
import affiliateReducer from "./affiliateReferrals/affiliateReducer";
import {getSentryReduxEnhancer} from "../utils/sentry";

const metaPersistConfig = {
    key: "meta",
    storage,
    whitelist: [
        "menuOrders",
        "dismissedWalletConnectPrompt",
        "lastSearchedPlaceId",
        "lastMenuOrderId",
        "lastRestaurantId",
        "magicLoginExternalReferralId",
        "referralCode",
        "accountLevel",
        "themePreference",
        "lastDeletionEmailSent",
        "checkedMagicActive",
        "dpayPriceExpiryTime",
        "isIndustryDismissed",
        "isGoFrensTutorialBannerDismissed",
        "lastActivePaymentMethod",
        "anonymousSpin",
        "isCheckoutFuelBannerDismissed",
        "isFuelUpModalDismissed",
        "isOverwolfDiscordBannerDismissed",
        "isDevourplayFeatureModalDismissed",
        "streamerModeEnabled",
    ],
};

const authPersistConfig = {
    key: "auth",
    storage,
    whitelist: ["fullToken"],
};

const affiliatePersistConfig = {
    key: "affiliate",
    storage,
    whitelist: [
        "referralCode",
        "referralExpiry",
    ],
};

const reducers: Reducer<any, any> = combineReducers({
    metaStore: persistReducer(metaPersistConfig, metaReducer),
    authStore: persistReducer(authPersistConfig, authReducer),
    affiliateStore: persistReducer(affiliatePersistConfig, affiliateReducer),
});

const middleware: StoreEnhancer = compose(
    applyMiddleware(promiseMiddleware),
    getSentryReduxEnhancer(),
);

export const store: Store<IStore> = createStore(reducers, defaultStore, middleware);
export const persistor = persistStore(store);
