import {ReactElement, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {EvmWallet, WalletType} from "@devour/client";
import {getUserFullName} from "../utils/getUserFullName";
import {truncateMiddle} from "../utils/truncateMiddle";
import FrameButton from "./buttons/FrameButton";
import WalletConnectRegistryModal from "./modals/WalletConnectRegistryModal";
import WalletInfoAlertIcon from "./WalletInfoAlertIcon";
import RemoveAWalletModal from "./modals/RemoveAWalletModal";
import {useAccount, useDisconnect} from "wagmi";
import {useLocation} from "react-router";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";
import SignUpToast from "./SignUpToast";
import { useStreamerMode } from "@/hooks/useStreamerMode";

function GoDexPageHeader(): ReactElement {

    const [
        promptedNumber,
        setPromptedNumber,
    ] = useState(0);
    const [
        showRemoveWalletModal,
        setShowRemoveWalletModal,
    ] = useState(false);
    const [
        showWalletConnectModal,
        setShowWalletConnectModal,
    ] = useState(false);
    const { hideCharacters} = useStreamerMode();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [
        showToast,
        setShowToast,
    ] = useState<boolean>(false);

    const location = useLocation();

    const {isConnected} = useAccount();
    const {disconnectAsync} = useDisconnect();

    const {data: userProfileData} = useGetUserProfile(fullToken);

    function toggleShowRemoveWalletModal(): void {
        setShowRemoveWalletModal(s => !s);
    }

    async function toggleShowWalletConnectModal(select?: boolean): Promise<void> {
        if (isConnected) {
            await disconnectAsync();
        }
        setShowWalletConnectModal(s => select !== undefined
            ? select
            : !s);
    }

    function renderWallets(wallet: EvmWallet, index: number) {
        return (
            <FrameButton
                key={`wallet-${index}`}
                color="gray"
                size="pill"
                href={`https://opensea.io/${wallet.address}`}
            >
                {truncateMiddle(wallet.address, 12)}
            </FrameButton>
        );
    }

    async function handleToastDismissal() {
        setShowToast(false);
    }

    return (
        <>
            <WalletConnectRegistryModal
                isOpen={showWalletConnectModal}
                toggle={toggleShowWalletConnectModal}
                promptedNumber={promptedNumber}
            />

            <RemoveAWalletModal
                isOpen={showRemoveWalletModal}
                onClose={toggleShowRemoveWalletModal}
            />

            <div className="go-dex-page-header">
                <div className="go-dex-page-header_img-con">
                    {
                        fullToken
                            ? <>
                                {userProfileData?.user?.gravatar &&
                                <img
									    src={userProfileData?.user?.gravatar}
									    alt="User Profile"
                                />
                                }
                            </>
                            : <img
                                src={`${import.meta.env.VITE_CDN_URL}/images/FUEL.webp`}
                                alt="User Profile"
                            />
                    }
                </div>

                <div className="go-dex-page-header_other">
                    <h3 className="go-dex-page-header_other_name">
                        {fullToken
                            ? getUserFullName({
                                ...userProfileData?.user,
                                lastName: hideCharacters(userProfileData?.user?.lastName),
                            })
                            : "DevourGO Guest"}
                    </h3>

                    <div className="go-dex-page-header_other_items">
                        {userProfileData?.wallets?.length > 0 && userProfileData.wallets.map(renderWallets)}

                        <FrameButton
                            color="gray"
                            size="pill"
                            onClick={() => {
                                if (fullToken) {
                                    toggleShowWalletConnectModal().then()
                                        .catch();
                                    setPromptedNumber(promptedNumber + 1);
                                } else {
                                    setShowToast(true);
                                }
                            }}
                        >
                            Add Wallet
                        </FrameButton>

                        {userProfileData?.wallets?.filter(w => w.type !== WalletType.MAGIC && w.type !== WalletType.PRIVY && w.type !== WalletType.THIRDWEB).length > 0 &&
                        <FrameButton
                            color="gray"
                            size="pill"
                            onClick={toggleShowRemoveWalletModal}
                        >
                                Remove a Wallet
                        </FrameButton>
                        }

                        {
                            fullToken
                                ? <WalletInfoAlertIcon/>
                                : <SignUpToast
                                    message={location.pathname === "/gofriends"
                                        ? "Join DevourGO to unlock your GoFriends promos!"
                                        : "Join DevourGO and start your GoVIP Journey!"}
                                    isOpen={showToast}
                                    onDismiss={handleToastDismissal}
                                />
                        }

                    </div>

                    {userProfileData?.wallets?.length > 0 &&
                    <p className="go-dex-page-header_other_subtitle">
                            NFTs can take up to 15 min to refresh
                    </p>
                    }
                </div>
            </div>
        </>
    );
}
export default GoDexPageHeader;
