import {useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    OnboardingApi,
    UsersApi,
    OWUserSessionEventType,
    ThirdwebApi,
} from "@devour/client";
import {login} from "@/redux/auth/authActions";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {
    addError,
    decrementLoading,
    incrementLoading,
    updateAnonymousSpin,
    updateCurrentUser,
} from "@/redux/meta/metaActions";
import getConfig from "@/utils/getConfig";
import {removeReferralCode} from "@/redux/affiliateReferrals/affiliateActions";
import {logOverwolfUserEvent} from "@/utils/logOverwolfUserEvent";
import * as Sentry from "@sentry/react";
import getAnonymousSpin from "@/utils/getAnonymousSpin";
import {AnonymousSpinModalShownKey} from "@/components/modals/GoVipSpin2WinModal";
import useOnLogout from "@/hooks/useOnLogout";
import {Account} from "thirdweb/wallets";
import {LoginPayload, signLoginPayload} from "thirdweb/auth";
import { getUserEmail } from "thirdweb/wallets/in-app";
import {thirdwebAuthClient} from "@/components/auth/ThirdwebClient";

interface OnLoginComplete {
    isExistingUser: boolean;
}

/**
 * Hook to handle post-login functionality
 */
export default function useOnAuthThirdweb() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const signedUrlKey = searchParams.get("suk");
    const {sendMessageToOW, isOnOverwolf} = useOverwolfInterop();
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    const {devourLogout} = useOnLogout();

    async function onLoginComplete(walletAccount: Account, referralCode?: string): Promise<OnLoginComplete> {
        if (!walletAccount || isExecuting) {
            return;
        }

        setIsExecuting(true);
        dispatch(incrementLoading());

        try {
            const loginPayload = await new ThirdwebApi().getThirdwebLoginPayload({
                address: walletAccount.address,
                // chainId: sepolia.id.toString(),
            });
            console.log("loginPayload", loginPayload);
            const { signature, payload } = await signLoginPayload({
                payload: loginPayload as unknown as LoginPayload,
                account: walletAccount,
            });
            console.log("signature", signature);
            console.log("payload", payload);

            const email = await getUserEmail({
                client: thirdwebAuthClient,
            });
            let isExistingUser: boolean = true;
            if (email) {
                const existingRes = await new UsersApi(getConfig()).validateUserExistence({
                    email: email,
                });
                isExistingUser = existingRes._exists;
                if (!isExistingUser) {
                    await submitSignUp(signature, payload, referralCode);
                }
            }

            // call the login api with the Privy access key, if the API returns 200 with a token, then log the user in as normal
            const loginPayloadResult = await new ThirdwebApi().postThirdwebLoginPayload({
                thirdwebLoginBody: {
                    signedPayload: {
                        signature,
                        payload,
                    },
                },
            });

            // grab the profile to save into redux
            const userRes = await new UsersApi(getConfig(loginPayloadResult.token)).getProfile();

            //  save the token and profile into redux
            sendMessageToOW({
                type: "de:data",
                isLogin: true,
                payload: {
                    token: loginPayloadResult.token,
                    userData: userRes,
                },
            });
            dispatch(login(loginPayloadResult.token));
            dispatch(updateCurrentUser(userRes));
            dispatch(removeReferralCode());

            if (isOnOverwolf) {
                void logOverwolfUserEvent(OWUserSessionEventType.LOGIN);
            }

            Sentry.setUser({
                email: userRes.user.email,
            });
            Sentry.setContext("userInfo", userRes);

            if (signedUrlKey) {
                await new OnboardingApi(getConfig(loginPayloadResult.token)).mergeUserWithSignedUrlKey({
                    signedUrlKey: signedUrlKey,
                });
            }

            const anonymousSpin = getAnonymousSpin();
            if (anonymousSpin) {
                dispatch(updateAnonymousSpin(undefined));
                window[AnonymousSpinModalShownKey] = false;
            }

            return {
                isExistingUser,
            };
        } catch (err) {
            devourLogout();
            dispatch(await addError(err));
        } finally {
            dispatch(decrementLoading());
            setIsExecuting(false);
        }

    }

    async function submitSignUp(signature: `0x${string}`, payload: LoginPayload, referralCode?: string) {
        // call the login api with the magic_credential, if the API returns 200 with a token, then log the user in as normal
        const anonymousSpin = getAnonymousSpin();
        const res = await new ThirdwebApi().thirdwebRegister({
            thirdwebRegisterBody: {
                anonymousSpinValue: anonymousSpin?.spinValue,
                referralCode: referralCode,
                signedPayload: {
                    signature,
                    payload,
                },
            },
        });

        if (signedUrlKey) {
            await new OnboardingApi(getConfig(res.token)).mergeUserWithSignedUrlKey({
                signedUrlKey: signedUrlKey,
            });
        }
    }

    return {onLoginComplete};
}

