import {PropsWithChildren, ReactElement, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {IStore} from "@/redux/defaultStore";
import {useLocation} from "react-router";


function AuthenticatedRoute(props: PropsWithChildren): ReactElement {

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const history = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!fullToken?.token) {
            // handleErrorDispatch().then().catch();
            history(`/log-in?redirect=${location.pathname.slice(1)}`, {replace: true});
        }
    }, [
        fullToken,
        history,
    ]);

    if (!fullToken?.token || !currentUser) {
        return null;
    }

    return (
        <>
            {props.children}
        </>
    );
}

export default AuthenticatedRoute;
